// This regex is given by Backend Team
export const PLAYER_REGEX = /^[a-z][a-z0-9]{3,10}[a-z0-9]$/
export const PLAYER_REGEX_FOR_SEARCH = /^[a-z][a-z0-9]{2,10}[a-z0-9]$/

export const PLAYER_BANK_ACCOUNT_NAME = /^[\p{L}\p{Mark}.\s]+$/u
// This const is being use in bet-history pages, and bet-history store
export const FRONT_END_BET_STATUS = {
	cancelByAdmin: 'CANCEL_BY_ADMIN',
	cancelByProvider: 'CANCEL_BY_PROVIDER',
}
