export const state = () => ({
	loading: false,
	boLanguages: [],
	landingLanguages: [],
	// cancel token
	cancelToken: null,
})

export const actions = {
	async boLanguages({ commit, state }) {
		commit('setLoading', true);
		if (state.cancelToken) {
			state.cancelToken.cancel('');
		}
		commit('setCancelToken', this.$axios.CancelToken.source());
		try {
			const response = await this.$axios.get('/website-setting/languages', {
				cancelToken: state.cancelToken.token,
			});
			commit('boLanguages', response.data);
		} catch (error) {

		} finally {
			commit('setLoading', false);
		}
	},
	async landingLanguages({ commit }) {
		commit('setLoading', true);
		commit('setCancelToken', this.$axios.CancelToken.source());
		try {
			const response = await this.$axios.get('/website-setting/languages/landing');
			commit('landingLanguages', response.data);
		} catch (error) {

		} finally {
			commit('setLoading', false);
		}
	},
	async createLanguage({ dispatch, rootGetters }, form) {
		try {
			await this.$axios.post('/website-setting/languages', form)
			this.$toast.success(rootGetters['master/storeTl']('languageCreatedSuccessful'))
			dispatch('boLanguages', { is_active: true })
		} catch (error) {

		}
	},
	async updateBOLanguage({ dispatch, rootGetters }, data) {
		try {
			await this.$axios.put(`/website-setting/languages/${data.code}`, { is_active_bo: data.status })
			dispatch('master/languages', true, { root: true })
			dispatch('boLanguages', { is_active: true })
			this.$toast.success(rootGetters['master/storeTl']('languageSettingsUpdatedSuccessful'))
		} catch (error) {

		}
	},
	async updateLandingLanguage({ rootGetters, dispatch }, data) {
		try {
			await this.$axios.put(`/website-setting/languages/landing`, { ...data.body })
			dispatch('landingLanguages')
			this.$toast.success(rootGetters['master/storeTl']('languageSettingsUpdatedSuccessful'))
		} catch (error) {

		}

	},
	async updateDefaultCurrency({ dispatch }, currency) {
		try {
			await this.$axios.put(`/website-setting/languages/landing/set-default-currency/${currency}`);
			await dispatch('master/baseCurrency', { is_active: true }, { root: true });
			return true;
		} catch (error) {

			return false;
		}
	}
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	boLanguages: (state, v) => {
		state.boLanguages = v
	},
	landingLanguages: (state, v) => {
		state.landingLanguages = v
	},
	updateBOLanguageLoading: (state, v) => {
		state.updateBOLanguageLoading = v
	},
	updateLandingLanguage: (state, v) => {
		state.updateLandingLanguage = v
	},
	setCancelToken: (state, data) => {
		state.cancelToken = data
	}
}

export const getters = {
	searchLandingLanguages: (state) => (currencies, currency) => {
		if (!currency) return state.boLanguages;

		const selectedCurrency = currencies.find(el => el.code === currency);
		if (!selectedCurrency) return state.boLanguages;

		const languages = state.boLanguages.filter(el =>
			selectedCurrency.supported_languages.some(sEl => sEl.language === el.code)
		).map(data => {
			const langStatus = selectedCurrency.supported_languages.find(el => el.language === data.code);
			return { ...data, is_active_bo: langStatus.is_active, is_default: langStatus.is_default };
		});

		return languages.sort((a, b) => {
			return b.is_active && b.is_default ? 1 : a.is_active && a.is_default ? -1 : b.is_active - a.is_active;
		});
	},
	getLandingLanguages: (state) => {
		if (!state.landingLanguages) return []
		const languages = JSON.parse(JSON.stringify(state.landingLanguages)) || []
		return languages?.sort((a, b) => {
			return b.is_active && b.is_default ? 1 : a.is_active && a.is_default ? -1 : b.is_active - a.is_active;
		});
	},
	getBOLanguages: (state) => state.boLanguages,
}
